<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <v-card class="text-center transparent" flat>
            <v-card-text>
              <img src="../assets/404.svg" width="300">
              <h1 class="mb-3">Oops!</h1>
              <h3 class="mb-5">Désolé, nous n'avons pas trouvé ce que vous cherchiez.</h3>
              <v-btn color="primary" class="rounded-lg" depressed to="/">Dashboard</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "notFound"
}
</script>

<style scoped>

</style>